export default function useNormalizePath(path: string) {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
    }

    return path;
}
